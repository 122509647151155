<template>
  <FileViewer />
</template>
<script>
import FileViewer from '@/components/FileViewer'
import { defineComponent } from "vue"

export default defineComponent({
  components: { FileViewer },
})
</script>
